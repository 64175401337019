.raw_logo {
  height: 305;
  width: 440;
}

.a{
  opacity: 0;
  -webkit-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  -o-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  -moz-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  animation: dash2 2s linear forwards 0.5s, fill 1s ease forwards 4.5s;
}
.h{
  opacity: 0;
  -webkit-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  -o-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  -moz-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  animation: dash2 2s linear forwards 0.5s, fill 1s ease forwards 4.5s;
}
.m{
  opacity: 0;
  -webkit-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  -o-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  -moz-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  animation: dash2 2s linear forwards 0.5s, fill 1s ease forwards 4.5s;
}
.n{
  opacity: 0;
  -webkit-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  -o-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  -moz-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  animation: dash2 2s linear forwards 0.5s, fill 1s ease forwards 4.5s;
}
.x{
  opacity: 0;
  -webkit-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  -o-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  -moz-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  animation: dash2 2s linear forwards 0.5s, fill 1s ease forwards 4.5s;
}
@keyframes dash2{
  from{
    stroke: #ffffff;
    opacity: 0;
  }
  to{
    stroke-dashoffset: 0;
    opacity:1;
    stroke: #ffffff;
  }
}
@keyframes fill{
  from{
    fill: transparent;
  }
  to{
    fill: #ffffff;
  }
}
.brain{
  stroke-dasharray: 4000;
  stroke-dashoffset: 4000;
  -webkit-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  -o-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  -moz-animation: dash2 2s linear forwards 0.5s, fill 0.5s ease forwards 2.5s;
  animation: dash2 2s linear forwards 0.5s, fill 1s ease forwards 3s;
  stroke: #ffffff;
}

@keyframes dash{
  from{
    stroke-dashoffset: 4000;
  }
  to{
    stroke-dashoffset: 0;
    stroke: #ffffff;
  }
}
